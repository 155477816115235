<template>
  <div class="m-0 px-3 pt-4 pr-2">
    <div class="d-flex justify-content-between mx-3">
      <h1 class="m-0">Pipeline Reports</h1>
      <base-button size="md" id="export"
                   class="float-right mt-2 white-outline-button button-font title-btn-padding"
                   @click="exportPipelinePatientReport"
                   :disabled="downloadingReport"
                   type="secondary">
        <img alt="Image placeholder" class="mt--1" src="/img/svg/export.svg">
        Export
      </base-button>
    </div>
    <el-tabs
      class="mx-3 my-4 pipeline-report-graph-tabs"
      type="border-card"
      v-model="tab"
    >
      <el-tab-pane label="Days To Complete">
        <keep-alive>
          <average-patients v-if="tab == 0" :loader="loaders.response" :pipelines-array="pipelines"
                            :checklist-templates-array="checklistTemplates" ref="averageDaysPipelinePatientReport"/>
        </keep-alive>
      </el-tab-pane>
      <el-tab-pane label="Patients By Stage">
        <keep-alive>
          <patients-by-stage v-if="tab == 1" :loader="loaders.response" :pipelines-array="pipelines"
                             :checklist-templates-array="checklistTemplates" ref="byStagePipelinePatientReport"/>
        </keep-alive>
      </el-tab-pane>
      <el-tab-pane label="Patients By Month">
        <keep-alive>
          <patient-by-months v-if="tab == 2" :loader="loaders.response" :pipelines-array="pipelines"
                             :checklist-templates-array="checklistTemplates" ref="byMonthPipelinePatientReport"/>
        </keep-alive>
      </el-tab-pane>
    </el-tabs>
    <div class="tempClassUCFS">
      <user-category-sidebar
        :show-surgery-date-filter="true"
        ref="userCategorySidebar"
      />
    </div>
  </div>
</template>

<script>
import {Option, Select, Table, TableColumn} from "element-ui";
import moment from "moment-timezone";
import {mapState} from "vuex";
import UserCategorySidebar from "../../../Components/UserCategorySidebar";
import AveragePatients from "@/views/Pages/Seminar2Surgery/PipelinePatientsReport/AveragePatients";
import PatientsByStage from "@/views/Pages/Seminar2Surgery/PipelinePatientsReport/PatientsByStage";
import PatientByMonths from "@/views/Pages/Seminar2Surgery/PipelinePatientsReport/PatientByMonths";

export default {
  name: "PipelinePatients",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    UserCategorySidebar,
    AveragePatients,
    PatientsByStage,
    PatientByMonths
  },
  data() {
    return {
      tab: 0,
      loaders: {
        response: false,
      },
      pipelines: [],
      checklistTemplates: [],
    }
  },
  mounted() {
    this.fetchPipelines()
  },
  computed: {
    // Mapping vuex states into the component using mapState Helper
    ...mapState({
      downloadingReport: state => state.modals.downloadingReport
    }),
  },
  methods: {

    /**
     * Export pipeline patient report
     */
    exportPipelinePatientReport() {
      if (this.tab == 0) {
        this.$refs.averageDaysPipelinePatientReport.exportPipelinePatientAverageDaysReport()
      } else if (this.tab == 1) {
        this.$refs.byStagePipelinePatientReport.exportPipelinePatientByStageReport()
      } else if (this.tab == 2) {
        this.$refs.byMonthPipelinePatientReport.exportPipelinePatientByMonthsReport()
      }
    },

    /**
     * Fetch all the program pipelines
     * @param userCategoriesObject
     */
    fetchPipelines(userCategoriesObject) {
      let vm = this
      vm.loaders.response = true
      vm.$store.dispatch('_getAllPipelines')
        .then(response => {
          vm.pipelines = response.data.data
          vm.getChecklistActiveTemplates()
        })
        .catch(error => {
          vm.loaders.response = false
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Pipelines',
            message: message
          })
        }).finally(() => {
        vm.loaders.response = false
      })
    },

    /**
     * Get all active checklist templates
     */
    getChecklistActiveTemplates() {
      let vm = this
      vm.$store.dispatch('_getAllActiveChecklistTemplates')
        .then(response => {
          vm.checklistTemplates = response.data.data
        }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Checklist Template',
          message: message
        })
      })
    },
  },
  watch :{
    'tab': function(newVal) {
      this.$refs.userCategorySidebar.clearSurgeryDateFilter();
      this.$refs.userCategorySidebar.setDefaultCategories();
    }
  }
}
</script>

<style scoped>

.pipeline-report-graph-tabs >>> .el-tabs__content {
  padding: 0;
}

</style>
