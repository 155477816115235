<template>
  <div>
    <div class="p-4">
      <div v-loading="loaders.response || loader" class="pb-4">
        <div class="d-flex align-items-center flex-wrap">
          <div class="d-inline-block mr-1 mb-2">
            <label class="form-control-label">Pipeline</label>
            <el-select
              class="w-100"
              v-model="request.pipeline"
              placeholder="Select Duration"
              @change="changePipeline"
            >
              <el-option
                v-for="(pipeline, key) in pipelines"
                class="select-danger"
                :value="pipeline.id"
                :label="pipeline.editable_title"
                :key="key">
              </el-option>
            </el-select>
          </div>
          <div class="d-inline-block mr-1 mb-2">
            <label class="form-control-label">Checklist Template</label>
            <el-select
              class="w-100"
              v-model="request.checklistTemplate"
              placeholder="Select Checklist Template"
            >
              <el-option
                :value="null"
                label="All"
                key="all">
              </el-option>
              <el-option
                v-for="(checklistTemplate, key) in checklistTemplates"
                class="select-danger"
                :value="checklistTemplate.id"
                :label="`${checklistTemplate.name} (${checklistTabs[checklistTemplate.temp_stage]})`"
                :key="key">
              </el-option>
            </el-select>
          </div>
          <div class="d-inline-block mr-1 mb-2">
            <label class="form-control-label">From (Stage)</label>
            <el-select
              class="w-100"
              v-model="request.fromStage"
              placeholder="Select From Stage"
            >
              <el-option
                :value="0"
                label="All"
                key="all">
              </el-option>
              <el-option
                v-for="(stage, key) in pipelineStages"
                class="select-danger"
                :value="stage.id"
                :label="stage.title"
                :key="key">
              </el-option>
            </el-select>
          </div>
          <div class="d-inline-block mr-1 mb-2">
            <label class="form-control-label">To (Stage)</label>
            <el-select
              class="w-100"
              v-model="request.toStage"
              placeholder="Select To Stage"
            >
              <el-option
                :value="0"
                label="All"
                key="all">
              </el-option>
              <el-option
                v-for="(stage, key) in pipelineStages"
                class="select-danger"
                :value="stage.id"
                :label="stage.title"
                :key="key">
              </el-option>
            </el-select>
          </div>
          <div class="d-inline-block mr-1 mb-2">
            <label class="form-control-label">Date Range</label>
            <el-date-picker
              class="w-100 date-ranger-picker"
              v-model="request.date"
              type="daterange"
              format="MM/dd/yyyy"
              range-separator="-"
              start-placeholder="Start date"
              end-placeholder="End date">
            </el-date-picker>
          </div>
          <base-button type="secondary"
                       class="d-inline-block py-2 ml-auto align-self-center font-weight-normal filter-button-width"
                       @click="$store.commit('showUCFS')">
            <img class="mr-2" src="/img/svg/filters.svg" alt="Filters"> Filter
            <el-badge :value="getFiltersCount" class="mark filter-count-badge p-0" type="warning"></el-badge>
          </base-button>
          <base-button @click="callApiForPatientsByPipeline"
                       size="md"
                       class="font-weight-normal filter-button-width btn-primary-action"
                       type="secondary">
            Run Report
          </base-button>
        </div>
      </div>
      <hr>
      <div v-if="patientsCount > 0">
        <div v-loading="loaders.response || loader">
          <h3 class="pb-2">Total Average Days: {{ getAverageDays }}</h3>
          <h3 class="pb-4">No. of Patients: {{ totalPatients }}</h3>
          <bar-chart
            :chart-data="averagePatientsChartData"
            :extra-options="chartOptions"
            :height="400"
          />
        </div>
        <hr>
        <div v-loading="loaders.response || loader">
          <el-table
            :data="filteredPatients.filter(data => !searchName || data[0].name.toLowerCase().includes(searchName.toLowerCase()))"
            header-row-class-name="thead-light-flexible-height"
            class="show-hidden-scrollbar"
          >
            <el-table-column align="left" width="160px" type="index">
              <template slot="header" slot-scope="scope">
                <el-input
                  v-model="searchName"
                  size="mini"
                  placeholder="Search by Name"/>
              </template>
            </el-table-column>
            <el-table-column min-width="200px" label="NAME">
              <template v-slot="slot">
                {{ slot.row[0].name }}
              </template>
            </el-table-column>
            <el-table-column
              v-for="stage in response.filteredStages"
              :label="stage.title"
              :key="stage.id"
              min-width="200px"
            >
              <template v-slot="{row}">
                {{ getDaysDifference(row, stage) }}
              </template>
            </el-table-column>
          </el-table>
          <custom-pagination
            class="pagination-no-border float-right mt-4"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="pagination.pageTotal"
            @input="changePage"
          >
          </custom-pagination>
        </div>
      </div>
      <div v-loading="loaders.response || loader" v-else class="d-flex">
        <span class="empty-text">{{ emptyText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import {Option, Select, Table, TableColumn} from "element-ui";
import BarChart from "@/components/Charts/BarChart";
import {mapState} from "vuex";
import fileDownload from "@/mixins/fileDownload";
import CustomPagination from "@/views/Components/Pagination/CustomPagination";

export default {
  name: "AveragePatients",
  props: {
    loader: {
      type: Boolean,
      default: false,
    },
    pipelinesArray: {
      type: Array,
      default: () => [],
    },
    checklistTemplatesArray: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BarChart,
    CustomPagination
  },
  data() {
    return {
      loaders: {
        response: false,
      },
      pagination: {
        currentPage: 1,
        perPage: 10,
        pageTotal: 1,
      },
      request: {
        pipeline: 0,
        checklistTemplate: null,
        fromStage: 0,
        toStage: 0,
        date: [],
        surgeryDate: '',
        surgeryDateDuration: '',
      },
      response: {
        filteredStages: [],
        patientTags: [],
        defaultPatientTags: [],
        filteredPipelinePatients: [],
      },
      filteredPatients: [],
      searchName: '',
      totalPatients: 0,
      emptyText: 'This report shows how many days, on average, it takes your patients to go from one stage to ' +
        'another stage in your pipelines. Select options above and click ‘Run Report’ to run a report.',
      filtersCount: 0,
      checklistTabs: {
        1: 'Pre-Op',
        2: 'Post-Op',
        3: 'Weight Management',
      },
      averagePatientsData: [],
      averagePatientsChartData: {
        datasets: [{
          label: 'Average Days',
          data: [0],
          backgroundColor: "#618CA4",
        }],
        labels: [''],
      },
      chartOptions: {
        responsive: true,
        scales: {
          xAxes: [{
            maxBarThickness: 20
          }]
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              return Math.round(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]) + ' Days'
            }
          }
        },
      }
    }
  },
  watch: {
    pipelines(newVal) {
      if (newVal.length) {
        this.request.pipeline = newVal[0].id
        this.getFiltersData()
      }
    }
  },
  mounted() {
    let vm = this

    if (vm.pipelines.length) {
      vm.request.pipeline = vm.pipelines[0].id
      vm.getFiltersData()
    }
  },
  activated() {
    let vm = this

    this.$root.$on('UserSubCategoryFilterCount', (data) => {
      vm.filtersCount = data
    })

    this.$root.$on('applyUserSubCategoryFilters', (data) => {
      vm.filtersCount = data.filtersCount;
      vm.request.surgeryDate = data.surgeryDate;
      vm.request.surgeryDateDuration = data.surgeryDateDuration;
      vm.getPatientByPipeline(data.obj)
    })
  },
  deactivated() {
    this.$root.$off('UserSubCategoryFilterCount');
    this.$root.$off('applyUserSubCategoryFilters');
  },
  mixins: [
    fileDownload
  ],
  computed: {
    // Mapping vuex states into the component using mapState Helper
    ...mapState({
      downloadingReport: state => state.modals.downloadingReport
    }),

    patientsCount() {
      return this.averagePatientsData.length ? this.averagePatientsData.reduce((a, b) => a + b, 0) : 0
    },

    pipelines() {
      return this.pipelinesArray.length ? this.pipelinesArray : []
    },

    checklistTemplates() {
      return this.checklistTemplatesArray.length ? this.checklistTemplatesArray : []
    },

    getFiltersCount() {
      return this.filtersCount ?? 0
    },

    pipelineStages() {
      const pipeline = this.pipelines.filter(pipeline => {
        return pipeline.id == this.request.pipeline
      })
      return pipeline.length ? pipeline[0].stages : []
    },

    getAverageDays() {
      return this.averagePatientsData.length ? this.averagePatientsData.reduce((a, b) => a + b, 0) : 0
    }
  },
  methods: {
    /**
     * Get days difference
     * @param row
     * @param stage
     * @returns {string|*}
     */
    getDaysDifference(row, stage) {
      if (stage.title == 'Total') {
        let totalDaysDiff = 0
        row.forEach(item => {
          totalDaysDiff += item.days_diff
        })
        return totalDaysDiff
      } else {
        for (const daysObject in row) {
          if (row[daysObject].stage_id == stage.id) {
            return (parseFloat(row[daysObject].days_diff) < 1) ? 0 : row[daysObject].days_diff ?? '-'
          }
        }
        return '-'
      }
    },

    changePipeline() {
      this.request.fromStage = 0
      this.request.toStage = 0
    },

    /**
     * Call for get patients by pipeline
     */
    callApiForPatientsByPipeline() {
      this.getPatientByPipeline(this.getPatientTagsObject())
    },

    /**
     * Change stage filter and get pipeline patients
     */
    checkSelectedStagesOrder() {
      let vm = this
      if (vm.request.fromStage <= 0 && vm.request.toStage <= 0) return true

      const fromOrderStage = vm.pipelineStages.find(stage => {
        return stage.id == vm.request.fromStage
      })

      const toOrderStage = vm.pipelineStages.find(stage => {
        return stage.id == vm.request.toStage
      })

      if (fromOrderStage && toOrderStage && fromOrderStage['order_key'] <= toOrderStage['order_key']) {
        return true
      } else {
        vm.$notify.info({
          title: 'Patient Tags',
          message: 'From stage must greater than to stage'
        })
        return false
      }
    },

    /**
     * Get filters data
     */
    getFiltersData() {
      let vm = this
      const payload = {
        page: 'pipeline-report'
      }

      vm.$store.dispatch('_getFilters', payload).then(response => {
        vm.response.patientTags = response.data.data.patientTags
        vm.response.defaultPatientTags = response.data.data.defaultPatientTags

        const userCategoriesObject = {
          categories: vm.response.patientTags,
          defaultCategories: vm.response.defaultPatientTags
        }
        this.$store.commit('setUserSubcategories', userCategoriesObject)
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Patient Tags',
          message: message
        })
      })
    },

    /**
     * Prepare and return user categories object
     * @returns {*[]}
     */
    getPatientTagsObject() {
      let vm = this, patientTagsObj = []
      vm.response.patientTags.forEach(patientTag => {
        if (patientTag.state && !isNaN(patientTag.state)) {
          patientTagsObj.push(patientTag.state);
        }
      })

      return patientTagsObj
    },

    /**
     * Get date range object for filtering
     * @returns {string[]|*[]}
     */
    getDateRangeObject() {
      if (this.request.date && this.request.date.length) {
        const fromDate = moment(this.request.date[0]).format('L')
        const toDate = moment(this.request.date[1]).format('L')
        return [fromDate, toDate]
      } else {
        return [null, null]
      }
    },

    /**
     * Get all patients count by pipeline
     */
    getPatientByPipeline(userCategoriesObject = null) {
      let vm = this
      vm.loaders.response = true
      if (vm.checkSelectedStagesOrder() == true) {
        const dateRangeObject = vm.getDateRangeObject()
        vm.loaders.response = true
        vm.$store.dispatch('_getPatientAverageDaysByPipeline', {
          pipelineId: vm.request.pipeline,
          checklistTemplateId: vm.request.checklistTemplate,
          fromStageId: vm.request.fromStage,
          toStageId: vm.request.toStage,
          fromDate: dateRangeObject[0],
          toDate: dateRangeObject[1],
          surgeryDate: vm.request.surgeryDate,
          surgeryDateDuration: vm.request.surgeryDateDuration,
          patientSubTags: userCategoriesObject && userCategoriesObject.length > 0 ? userCategoriesObject : null
        })
          .then(response => {
            vm.updateChartData(response.data.data[0], Object.values(response.data.data[1]))
            vm.response.filteredStages = response.data.data[4]
            vm.totalPatients = response.data.data[2]
            vm.prepareTableData(response.data.data[3])
          })
          .catch(error => {
            const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
            vm.$notify.error({
              title: 'Pipeline Patients',
              message: message
            })
          })
          .finally(() => {
            vm.loaders.response = false
          })
      } else {
        vm.loaders.response = false
      }
    },

    /**
     * Prepare table data
     * @param filteredPatients
     */
    prepareTableData(filteredPatients) {
      let filteredPatientsArray = []
      for (const id in filteredPatients) {
        filteredPatientsArray.push(filteredPatients[id])
      }

      this.response.filteredPipelinePatients = filteredPatientsArray
      this.pagination.currentPage = 1
      this.pagination.perPage = 10
      this.pagination.pageTotal = filteredPatientsArray.length
      this.changePage(1)
    },

    changePage(currentPage) {
      this.filteredPatients = []
      this.searchName = ''
      this.pagination.currentPage = currentPage
      const startIndex = ((currentPage - 1) * 10) + 1
      const endIndex = currentPage * 10

      for (let i = startIndex; i <= endIndex; i++) {
        if (this.response.filteredPipelinePatients[i - 1] != undefined) {
          this.filteredPatients.push(this.response.filteredPipelinePatients[i - 1])
        }
      }
    },

    /**
     * Export pipeline patient report
     */
    exportPipelinePatientAverageDaysReport() {
      let vm = this
      vm.$store.commit('toggleDownloadReport', true)
      const dateRangeObject = vm.getDateRangeObject()
      const patientTagsObject = this.getPatientTagsObject()
      const params = {
        pipelineId: vm.request.pipeline,
        checklistTemplateId: vm.request.checklistTemplate,
        fromStageId: vm.request.fromStage,
        toStageId: vm.request.toStage,
        fromDate: dateRangeObject[0],
        toDate: dateRangeObject[1],
        patientSubTags: patientTagsObject,
        surgeryDate: vm.request.surgeryDate,
        surgeryDateDuration: vm.request.surgeryDateDuration,
      }
      const config = {
        responseType: "blob",
        params
      }
      vm.$store.dispatch('_pipelinePatientsAverageDaysExcel', config)
        .then(response => {
          if (vm.downloadingReport) {
            vm.generateExportFileDownload(response, 'Baritastic-Pipeline-Patients-Average-Days')
          }
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Pipeline Patients Report',
            message: message
          })
        })
        .finally(() => {
          vm.$store.commit('toggleDownloadReport', false)
        })
    },

    /**
     * Update the charts data
     * @param selectedStages
     * @param averagePatientsData
     */
    updateChartData(selectedStages, averagePatientsData) {
      let vm = this
      vm.averagePatientsData = averagePatientsData

      if (this.patientsCount <= 0) {
        this.emptyText = 'No data found. Please change your query and try again.'
      }

      vm.averagePatientsChartData = {
        datasets: [{
          label: 'Average Patients Data',
          data: averagePatientsData,
          backgroundColor: "#618CA4",
        }],
        labels: selectedStages,
      }
    }
  }
}
</script>

<style scoped>
/* Explicitly showing scroll bar even if gets hide */
.show-hidden-scrollbar >>> .el-table__body-wrapper {
  overflow: auto !important;
}

.filter-button-width {
  margin-top: 22px;
}

.date-ranger-picker {
  min-height: 43px;
}
</style>
