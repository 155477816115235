<template>
  <div>
    <div v-loading="loaders.response || loader" class="p-4">
      <div class="pb-4">
        <div class="d-flex align-items-center flex-wrap">
          <div class="d-inline-block mr-1 mb-2">
            <label class="form-control-label">Pipeline</label>
            <el-select
              class="w-100"
              v-model="request.pipeline"
              placeholder="Select Duration"
            >
              <el-option
                v-for="(pipeline, key) in pipelines"
                class="select-danger"
                :value="pipeline.id"
                :label="pipeline.editable_title"
                :key="key">
              </el-option>
            </el-select>
          </div>
          <div class="d-inline-block mr-1 mb-2">
            <label class="form-control-label">Checklist Template</label>
            <el-select
              class="w-100"
              v-model="request.checklistTemplate"
              placeholder="Select Checklist Template"
            >
              <el-option
                :value="null"
                label="All"
                key="all">
              </el-option>
              <el-option
                v-for="(checklistTemplate, key) in checklistTemplates"
                class="select-danger"
                :value="checklistTemplate.id"
                :label="`${checklistTemplate.name} (${checklistTabs[checklistTemplate.temp_stage]})`"
                :key="key">
              </el-option>
            </el-select>
          </div>
          <base-button type="secondary"
                       class="d-inline-block py-2 ml-auto align-self-center font-weight-normal filter-button-width"
                       @click="$store.commit('showUCFS')">
            <img class="mr-2" src="/img/svg/filters.svg" alt="Filters"> Filter
            <el-badge :value="getFiltersCount" class="mark filter-count-badge p-0" type="warning"></el-badge>
          </base-button>
          <base-button @click="callApiForPatientsByPipeline"
                       size="md"
                       class="font-weight-normal filter-button-width btn-primary-action"
                       type="secondary">
            Run Report
          </base-button>
        </div>
      </div>
      <hr>
      <div v-if="patientsCount > 0">
        <h4 class="pb-2">Pipeline Patients By Stage</h4>
        <bar-chart
          :chart-data="stagesChartData"
          :extra-options="chartOptions"
          :height="400"
        />
      </div>
      <div v-else class="d-flex">
        <span class="empty-text">{{ emptyText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {Option, Select} from "element-ui";
import BarChart from '@/components/Charts/BarChart';
import fileDownload from "@/mixins/fileDownload";
import {mapState} from "vuex";

export default {
  name: "PatientsByStage",
  props: {
    loader: {
      type: Boolean,
      default: false,
    },
    pipelinesArray: {
      type: Array,
      default: () => [],
    },
    checklistTemplatesArray: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BarChart,
  },
  data() {
    return {
      loaders: {
        response: false,
      },
      request: {
        pipeline: 0,
        checklistTemplate: null,
        surgeryDate: '',
        surgeryDateDuration: ''
      },
      response: {
        stages: [],
        patientTags: [],
        defaultPatientTags: [],
      },
      emptyText: 'This report shows how many patients currently are in each stage of your pipeline. Choose your settings and click ‘Run Report’.',
      filtersCount: 0,
      checklistTabs: {
        1: 'Pre-Op',
        2: 'Post-Op',
        3: 'Weight Management',
      },
      stagesChartData: {
        datasets: [{
          label: 'Pipeline Patients By Stage',
          data: [0],
          backgroundColor: "#618CA4",
        }],
        labels: [''],
      },
      chartOptions: {
        responsive: true,
        scales: {
          xAxes: [{
            maxBarThickness: 20
          }]
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              return Math.round(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]) + ' Patients'
            }
          }
        },
      }
    }
  },
  mounted() {
    let vm = this

    if (vm.pipelines.length) {
      vm.request.pipeline = vm.pipelines[0].id
      vm.getFiltersData()
    }
  },
  activated() {
    let vm = this

    this.$root.$on('UserSubCategoryFilterCount', (data) => {
      vm.filtersCount = data
    })

    this.$root.$on('applyUserSubCategoryFilters', (data) => {
      vm.filtersCount = data.filtersCount;
      vm.request.surgeryDate = data.surgeryDate;
      vm.request.surgeryDateDuration = data.surgeryDateDuration;
      vm.getPatientByPipeline(data.obj)
    })
  },
  deactivated() {
    this.$root.$off('UserSubCategoryFilterCount');
    this.$root.$off('applyUserSubCategoryFilters');
  },
  mixins: [
    fileDownload
  ],
  computed: {
    // Mapping vuex states into the component using mapState Helper
    ...mapState({
      downloadingReport: state => state.modals.downloadingReport
    }),

    patientsCount() {
      return this.stagesChartData.datasets[0].data.length ? this.stagesChartData.datasets[0].data.reduce((a, b) => a + b, 0) : 0
    },

    pipelines() {
      return this.pipelinesArray.length ? this.pipelinesArray : []
    },

    checklistTemplates() {
      return this.checklistTemplatesArray.length ? this.checklistTemplatesArray : []
    },

    getFiltersCount() {
      return this.filtersCount ?? 0
    },
  },
  methods: {

    /**
     * Call for get patients by pipeline
     */
    callApiForPatientsByPipeline() {
      this.getPatientByPipeline(this.getPatientTagsObject())
    },

    /**
     * Get filters data
     */
    getFiltersData() {
      let vm = this
      const payload = {
        page: 'pipeline-report'
      }

      vm.$store.dispatch('_getFilters', payload).then(response => {
        vm.response.patientTags = response.data.data.patientTags
        vm.response.defaultPatientTags = response.data.data.defaultPatientTags

        const userCategoriesObject = {
          categories: vm.response.patientTags,
          defaultCategories: vm.response.defaultPatientTags
        }
        this.$store.commit('setUserSubcategories', userCategoriesObject)
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
        vm.$notify.error({
          title: 'Patient Tags',
          message: message
        })
      })
    },

    /**
     * Prepare and return user categories object
     * @returns {*[]}
     */
    getPatientTagsObject() {
      let vm = this, patientTagsObj = []
      vm.response.patientTags.forEach(patientTag => {
        if (patientTag.state && !isNaN(patientTag.state)) {
          patientTagsObj.push(patientTag.state);
        }
      })

      return patientTagsObj
    },

    /**
     * Get all patients count by pipeline
     */
    getPatientByPipeline(userCategoriesObject = null) {
      let vm = this
      vm.loaders.response = true
      vm.$store.dispatch('_getPatientByPipeline', {
        pipelineId: vm.request.pipeline,
        surgeryDate: vm.request.surgeryDate,
        surgeryDateDuration: vm.request.surgeryDateDuration,
        checklistTemplateId: vm.request.checklistTemplate,
        patientSubTags: userCategoriesObject && userCategoriesObject.length > 0 ? userCategoriesObject : null
      })
        .then(response => {
          vm.response.stages = response.data.data[0]
          vm.updateChartData(response.data.data[4])
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Pipeline Patients',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.response = false
        })
    },

    /**
     * Export pipeline patient report
     */
    exportPipelinePatientByStageReport() {
      let vm = this
      vm.$store.commit('toggleDownloadReport', true)
      const patientTagsObject = this.getPatientTagsObject()
      const params = {
        pipelineId: vm.request.pipeline,
        checklistTemplateId: vm.request.checklistTemplate,
        patientSubTags: patientTagsObject,
        surgeryDate: vm.request.surgeryDate,
        surgeryDateDuration: vm.request.surgeryDateDuration,
      }
      const config = {
        responseType: "blob",
        params
      }
      vm.$store.dispatch('_pipelinePatientsExcel', config)
        .then(response => {
          if (vm.downloadingReport) {
            vm.generateExportFileDownload(response, 'Baritastic-Pipeline-Patients')
          }
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Pipeline Patients Report',
            message: message
          })
        })
        .finally(() => {
          vm.$store.commit('toggleDownloadReport', false)
        })
    },

    /**
     * Update the charts data
     * @param dataByStages
     */
    updateChartData(dataByStages) {
      let vm = this, stages = []

      if (this.patientsCount <= 0) {
        this.emptyText = 'No data found. Please change your query and try again.'
      }

      //Filtering stages from response array
      vm.response.stages.forEach(stage => {
        if (stage.title != 'Total') {
          stages.push((stage.title.trim().length > 25) ? stage.title.substring(0, 25) + '...' : (stage.title))
        }
      })

      vm.stagesChartData = {
        datasets: [{
          label: 'Pipeline Patients By Stage',
          data: dataByStages,
          backgroundColor: "#618CA4",
        }],
        labels: stages,
      }
    }
  }
}
</script>

<style scoped>
.filter-button-width {
  margin-top: 22px;
}

</style>
